import styles from '@styles/scss/list/_modelGrid.module.scss';
import { Banner } from 'lib/types/item/banner';
import { Category } from 'lib/types/item/category';
import { GenderButton } from 'lib/types/item/genderButtons';
import { PerformerCard } from 'lib/types/item/performerCard';
import { useEffect, useState } from 'react';

import BannerCTA from '../common/bannerCTA';
import GenderButtons from '../common/genderButtons';
import HeroContent from '../common/heroSection';
import Card from '../performer/card';

export default function ModelGrid({
    performerCards,
    category,
    banner,
    genderButtons,
    currentLang,
    gender
}: {
    performerCards: PerformerCard[];
    category: Category;
    banner: Banner;
    genderButtons: GenderButton;
    currentLang: string;
    gender: string;
}): JSX.Element {
    const [randomIndex, setRandomIndex] = useState(15);
    const [isGalleryHovered, setIsGalleryHovered] = useState(false);

    const handleMouseEnter = () => setIsGalleryHovered(true);
    const handleMouseLeave = () => setIsGalleryHovered(false);

    const onlinePerformers = performerCards.filter(performer => performer.is_online !== null);

    useEffect(() => {
        const interval = setInterval(() => {
            const randomNumber = Math.floor(Math.random() * 7) + 1;
            setRandomIndex(randomNumber);
        }, 10000);

        if (isGalleryHovered === true) {
            setRandomIndex(15); // doing this will reset the timer, so autoplay doesnt start right up again
        }
        return () => clearInterval(interval);
    }, [isGalleryHovered]);

    return (
        <div className={styles.performersContainer}>
            <GenderButtons currentLang={currentLang} genderButtons={genderButtons} />
            <div className={styles.similarPerformers}>
                <HeroContent category={category} currentLang={currentLang} />
            </div>
            <div className={styles.performers}>
            {onlinePerformers
                    .slice(0, 12)
                    .map((performer, index) => (
                    <div
                        key={performer.username}
                        className={styles.performer}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        <Card
                            key={performer.username}
                            performerCard={performer}
                            currentLang={currentLang}
                            gender={gender}
                            randomNumber={randomIndex}
                            index={index}
                            currentlyHovered={isGalleryHovered}
                        />
                    </div>
                ))}
            </div>
            <BannerCTA
                banner={banner}
                bannerText=""
                gender={gender}
                currentLang={currentLang}
                Ecategory="Outbound Click"
                Eaction="Join Click"
                Elabel1="Floating-CTA1"
                Elabel2="Floating-Text1"
            />
            <div className={styles.performers}>
                {onlinePerformers
                  .slice(12, 24)
                  .map((performer, index) => (
                    <div
                        key={performer.username}
                        className={styles.performer}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        <Card
                            key={performer.username}
                            performerCard={performer}
                            currentLang={currentLang}
                            gender={gender}
                            index={15} // number that won't trigger autoplay
                            randomNumber={index}
                            currentlyHovered={isGalleryHovered}
                        />
                    </div>
                ))}
            </div>
            {performerCards.length >= 24 && (
                <BannerCTA
                    banner={banner}
                    bannerText={banner.text_2nd_block}
                    gender={gender}
                    currentLang={currentLang}
                    Ecategory="Outbound Click"
                    Eaction="Join Click"
                    Elabel1="Floating-CTA2"
                    Elabel2="Floating-Text2"
                />
            )}
            <div className={styles.performers}>
            {onlinePerformers
                  .slice(24, 36)
                  .map((performer, index) => (
                    <div
                        key={performer.username}
                        className={styles.performer}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        <Card
                            key={performer.username}
                            performerCard={performer}
                            currentLang={currentLang}
                            gender={gender}
                            randomNumber={15} // number that won't trigger autoplay
                            index={index}
                            currentlyHovered={isGalleryHovered}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
